import { DragDropFile } from "../DragDropFile";

function VideoEditor() {
    return (
        <div>
            <h1>Video Editor</h1>
            <button>Create a new Video Workspace</button>
            <DragDropFile/>
        </div>
    );
}

export { VideoEditor };