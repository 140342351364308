import { AuthenticatedApp } from './components/AuthenticatedApp';
import { UnauthenticatedApp } from './components/UnauthenticatedApp';
import { useAuth } from './hooks/useAuth';
import './App.css';

function App() {
    const { user, logout } = useAuth();

    console.log(user);
    return (
        <div className="container">
            <h1>M^n</h1>
            <div>
            {user ? <><img src={user.photoURL} alt="profile"/>{" | "}<label><b>{user.displayName}</b></label>{" | "}<button onClick={logout}>Logout</button></> : <></> }
            </div>
            {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </div>
    );
}

export default App;