import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Landing } from '../Landing';
import { VideoEditor } from '../VideoEditor';
import { SocialMedia } from '../SocialMedia';
import { Reports } from '../Reports';

function AuthenticatedApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing />} >
                    <Route path="" element={<Reports />} />
                    <Route path="VideoEditor" element={<VideoEditor />} />
                    <Route path="SocialMedia" element={<SocialMedia />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export { AuthenticatedApp };