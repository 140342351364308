import './styles.css';
import { Link, Outlet } from 'react-router-dom';

function Landing() {
    return (
        <div>
        <nav> <Link to="/">M^n</Link> {" | "}<Link to="VideoEditor">Video Editor</Link>{" | "}<Link to="SocialMedia">Social Media Accounts</Link></nav>
        <Outlet />
        </div>
    );
}

export { Landing };